<template>
  <AuthenticationDialog>
    <v-form ref="form" v-model="valid" class="pb-8">
      <v-text-field
        v-model="email"
        :label="$t('authentication.email')"
        :persistent-placeholder="autofillFix"
        :rules="[validations.required, validations.email]"
        autocomplete="email"
        outlined
        placeholder=" "
      ></v-text-field>
      <v-text-field
        ref="email"
        v-model="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :label="$t('authentication.password')"
        :persistent-placeholder="autofillFix"
        :rules="[validations.required]"
        :type="showPassword ? 'text' : 'password'"
        autocomplete="password"
        class="pb-0 mb-0"
        outlined
        placeholder=" "
        @click:append="showPassword = !showPassword"
        @keyup.enter="login"
      ></v-text-field>
      <v-container
        class="pl-1 pt-0"
        fluid>
        <v-row class="mb-3">
          <v-col align-self="center" md="auto">
            <v-switch
              v-model="rememberMe"
              :label="$t('authentication.keepMeLoggedIn')"
              color="danger"
              inset
            ></v-switch>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align-self="center" md="auto">
            <router-link :to="{name: 'forgotPassword'}" class="font-weight-bold text--primary fade-weak">
              {{ $t('authentication.resetPassword') }}
            </router-link>
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        :disabled="loading"
        :loading="loading"
        block
        class="font-weight-bold login text-h5 danger secondary--text rounded-lg pa-8 shadow"
        x-large
        @click="login">
        {{ $t('authentication.login') }}
      </v-btn>
      <div class="fade-weak mt-8">
        {{ $t('authentication.strainlabsMonitoringSolution') }}
        <i18n path="authentication.forMoreInformation" tag="div">
          <template v-slot:contactUs>
            <router-link :to="{name: 'contactUs'}"><b>{{ $t('authentication.contactUs') }}</b></router-link>
          </template>
          <template v-slot:strainlabsAnalytics>
            <b class="pointer" @click="goToStrainlab">{{ $t('authentication.strainlabsAnalytics') }}</b>
          </template>
        </i18n>
      </div>
    </v-form>
  </AuthenticationDialog>
</template>

<script>
import AuthenticationDialog from "@/components/Authentication/AuthenticationDialog/AuthenticationDialog";
import validations from "slabasic/src/helper/validations";

export default {
  name: "LoginPage",
  components: {AuthenticationDialog},
  data() {
    return {
      valid: false,
      loading: false,
      email: this.$route.params.email ? this.$route.params.email : '',
      password: '',
      validations,
      autofillFix: false,
      showPassword: false,
      rememberMe: true,
    };
  },
  mounted() {
    this.autoLoginCheckingInterface();
    this.initRememberMe();
  },
  watch: {
    email() {
      this.autofillFix = false;
    },
    password() {
      this.autofillFix = false;
    },
    rememberMe(val) {
      this.$store.dispatch('setRememberMe', val);
    }
  },
  methods: {
    login() {
      if (!this.valid) {
        return;
      }
      this.loading = true;
      this.$store.dispatch('login', {
        username: this.email,
        password: this.password,
      }).then((passwordResetToken) => {
       if(passwordResetToken) {
         this.$router.push({name: 'changePassword', query: {token: passwordResetToken, email: this.email}});
       } else {
         location.reload();
         this.$router.push({name: 'home'});
         sessionStorage.removeItem('analyticsNewSession');
         sessionStorage.removeItem('configurationNewSession');
       }
      }).catch((err) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: err.response.data.messages
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    autoLoginCheckingInterface() {
      // each 100ms we check if the issue was produced
      let intervalDetectAutofill = setInterval(() => {
        if (
          // we target at least one of the stuff that will be affected by autofill
          // to do our checking
          document.querySelectorAll('input[type="password"]:-webkit-autofill')
            .length > 0
        ) {
          // and we inform the system about the issue if it is produced
          this.autofillFix = true;

          // we stop to check if issue was produced
          clearInterval(intervalDetectAutofill);
        }
      }, 100);

      // if after 3s nothing appear, means no autofill was made
      setTimeout(() => {
        if (intervalDetectAutofill) {
          clearInterval(intervalDetectAutofill);
          intervalDetectAutofill = null;
        }
      }, 3000);
    },
    goToStrainlab() {
      window.location = 'https://strainlabs.com/';
    },
    initRememberMe() {
      this.rememberMe = this.$store.getters.isRememberMe;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "login-page";
</style>